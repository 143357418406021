import React from 'react';
import { graphql, navigate } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';
import { Window, WindowContent, WindowHeader, Button } from 'react95';
import Img from 'gatsby-image';
import projectCard from '../components/projectCard';
import Footer from '../components/Footer';
import Layout from './BaseLayout';
import BaseLayoutSetup from './BaseLayoutSetup';

export default function ProjectWindow({ data, location }) {
  const card = data.markdownRemark;

  const { title: cardTitle, cardTags, featuredImage } = card.frontmatter;
  return (
    <BaseLayoutSetup>
      <ThemeProvider theme={original}>
        <Window className="window">
          <WindowHeader className="window-header">
            <Button
              onClick={() => {
                navigate('/portfolio');
              }}
            >
              <i className="fa fa-arrow-left button-icon" aria-hidden="true" />
            </Button>
            <span>{`${card.fields.slug.slice(11)}.exe`}</span>
            <Img
              fluid={featuredImage.childImageSharp.fluid}
              className="icon-image-container"
            />
          </WindowHeader>
          <WindowContent>
            <div className="project-window-content">
              {projectCard(
                {
                  cardTitle,
                  cardTags,
                  cardImage: featuredImage.childImageSharp.fluid,
                  cardType: location.state ? location.state.cardType : 'normal',
                  projectSlug: card.fields.slug,
                },
                true
              )}
              <p>Description of project coming soon!</p>
            </div>
          </WindowContent>
        </Window>
      </ThemeProvider>
    </BaseLayoutSetup>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
      rawMarkdownBody
    }
  }
`;
